






















































import {Component, Vue} from "vue-property-decorator";
import {namespace} from "vuex-class";
import {User, WorkOutPlan} from "@/api";
import workOutPlanService from "@/service/workout-plan.service";
import moment from "moment";
import {STANDARD_DATE} from "@/constants/date.constants";
import notificationService, {NotificationType} from "@/service/notification.service";

const Auth = namespace("Auth");

@Component
export default class WorkoutPlanEditTable extends Vue {
  @Auth.State("user")
  private currentUser!: User;

  workOutPlans: WorkOutPlan[] = [];
  loading = false;


  get headers(): any {
    return [
      {
        text: this.$t('cards.workOutPlan.header.id') as string,
        align: 'start',
        value: 'id',
      },
      {text: this.$t('cards.workOutPlan.header.date') as string, value: 'createdDate'},
      {text: this.$t('cards.workOutPlan.header.code') as string, value: 'group.code'},
      {text: this.$t('cards.workOutPlan.header.name') as string, value: 'group.name'},
      {text: this.$t('cards.workOutPlan.header.trainer') as string, value: 'trainer'},
      {text: this.$t('cards.workOutPlan.header.location') as string, value: 'group.location.name'},
      {text: this.$t('cards.workOutPlan.header.status.title') as string, value: 'status'},
    ]
  }


  private loadWorkoutPlans() {
    this.loading = true;
    workOutPlanService.getAllUserEditableWorkOutPlans()
        .then(resp => this.workOutPlans = resp.data)
        .catch(error => {
          notificationService.showNotification(NotificationType.error, error.response.data.detail)
        }).finally(() => {
      this.loading = false;
    })
  }

  rowClick(item: WorkOutPlan): void {
    this.$router.push({name: 'workoutPlanEdit', params: {id: item.id?.toString()!}})
  }

  formatDate(date: string): string {
    return moment(date).format(STANDARD_DATE)
  }

  mounted() {
    this.loadWorkoutPlans();
  }

}
